import '@/styles/global.css';

import type { AppProps } from 'next/app';
import { Nunito } from 'next/font/google';
import { appWithTranslation } from 'next-i18next';

const nunito = Nunito({
  subsets: ['latin'],
  display: 'swap',
  variable: '--font-nunito',
});

const MyApp = ({ Component, pageProps }: AppProps) => {
  return (
    <main className={nunito.className}>
      <Component {...pageProps} />
    </main>
  );
};

export default appWithTranslation(MyApp);
